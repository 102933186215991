import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal, Table } from "react-bootstrap";
import PageTitle from '../layout/PageTitle';
import Footer from "../layout/Footer";
import { toast } from "react-toastify";

//Data-table
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import 'datatables.net-dt/css/jquery.dataTables.css';
import ComplaintsListTableData from "./dashboard_data.json";
import 'jquery-ui-dist/jquery-ui.css'; // jQuery UI CSS for date picker
import 'jquery-ui-dist/jquery-ui.js';
import {getFromAPI,putToAPI,postToAPI,deleteFromAPI, setAutoReload, clearAutoReload,DEFAULT_INTERVAL_TIME } from "../Utils/utils"
import VehicleDetails from "./VehicleDetails";

export default function Dashboard() {

  // View Complaints modal
  const [vehicleModal, setVehicleModal] = useState(false);
  const vehicleModalClose = () => setVehicleModal(false);
  const vehicleModalShow = () => setVehicleModal(true);
  const [dashboardData,setDashBoardData] = useState([]);
  const [tableData, setTableData] = useState({}); // To store QR data for each vehicle
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [complaintsStatusData, setComplaintsStatusData] = useState([]);
  const [complete,setComplete] = useState(0);
  const [pending,setPending] = useState(0);
  const [inProcess,setInProcess] = useState(0);
  const [autoReloadId, setAutoReloadId] = useState(null); // State to store the interval ID
  const [vahicleAssignmentid, setVahicleAssignmentid] = useState();
  const [QRcanLogData, setQRcanLogData] = useState([]);
  const [WardData, setWardData] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState();
  const [loading, setLoading] = useState(true); // Track loading status

  // View Complaints modal
  const [vehicleDetailsModal, setVehicleDetailsModal] = useState(false);
  const vehicleDetailsModalClose = () => setVehicleDetailsModal(false);
  const vehicleDetailsModalShow = async (id, Date) => {
  try {
    // Fetch vehicle assignment details
    const vehicleResponse = await getFromAPI(`getassignedQR/?vehicle_id=${id}&date_assigned=${Date}&CheckStatus=true`);
    const vehicle_assignment_id = vehicleResponse.vehicle_assignment.id;

    // Ensure there are destinations before continuing
    if (vehicleResponse.destinations && vehicleResponse.destinations.length > 0) {

      // Fetch QR scan log details
      const qrScanResponse = await getFromAPI(`qrscanlog/?vehicle_assignment_id=${vehicle_assignment_id}`);
      
      let vehicleDetailsList = [];

      // Loop through each destination
      vehicleResponse.destinations.forEach((destination) => {
        // Find if this destination exists in the QR scan log data
        const matchedLog = qrScanResponse.qrScanLogData.find(
          (log) => parseInt(log.destinationId) === parseInt(destination.destination_id)
        );
        
        var ward = WardData.filter(o => o.id == destination?.Ward);
        // Set the done status based on whether the destination was found in the QR scan log
        const doneStatus = matchedLog ? true : false;

        // Construct vehicle details for each destination
        const vehicleDetails = {
          id: vehicleResponse?.vehicle_assignment?.id ?? "NA", // Use "NA" if id is missing
          date_assigned: Date ?? "NA", // Use "NA" if Date is missing
          destination: destination?.destination_name ?? "NA", // Use "NA" if destination_name is missing
          qrCode: destination?.qr_name ?? "NA", // Use "NA" if qr_name is missing
          ward: ward[0]?.name ?? "NA", // Use "NA" if Ward is missing
          zone: ward[0]?.zone?.name ?? "NA",
          done: doneStatus ?? false, // Default to false if doneStatus is missing
          vehicle_assignment_id: vehicleResponse?.vehicle_assignment?.id ?? "NA",
          destinationId : destination?.destination_id??"NA", //
          qrCodeId : destination?.qr_code??"NA",
          qrScanResponse : matchedLog?.id??"NA",

        };

        // Add vehicle details to the list
        vehicleDetailsList.push(vehicleDetails);
      });

      // Set the list of vehicle details and show modal
      setVehicleDetails(vehicleDetailsList);
      setVehicleDetailsModal(true);

      } else {
        toast.error('No destinations found');
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while fetching vehicle details');
    }
  };
  // TODO: Create Complaint object with status using API data.

  const fetchDashBoardData = async () => {
    // Get values from input elements (or set to today's date if not provided)
    const fromDateInput = $("#fromDate").val() || new Date().toISOString().split('T')[0]; // Default to today if not selected
    const toDateInput = $("#toDate").val() || new Date().toISOString().split('T')[0]; // Default to today if not selected

    const fromDate = new Date(fromDateInput);
    const toDate = new Date(toDateInput);

    // Check for invalid date range
    if (!isNaN(fromDate) && !isNaN(toDate) && fromDate > toDate) {
      toast.error("Start date cannot be greater than end date.");
      return; // Do not proceed if the date range is invalid
    }
    
    // API request to fetch dashboard data with date filters
    const response = await getFromAPI(`Vehicleassignment/?FromDate=${fromDate.toISOString().split('T')[0]}&ToDate=${toDate.toISOString().split('T')[0]}`);
    
    if (response.status) {
      if (response.vehicleMappingData.length <= 0) 
      {
        toast.info('No Data Found');
        return;
      }
      setDashBoardData(response.vehicleMappingData);
    }
};

const fetchqrscanlogdata = () => {
  getFromAPI("qrscanlog/").then(data => {
    setQRcanLogData(data.qrScanLogData);
  }).catch(error => {
    console.log(error);
  });
}

const fetchwarddata = () => {
  getFromAPI("ward/").then(data => {
    setWardData(data.ward);
  }).catch(error => {
    console.log(error);
  });
}

// Fetch assigned QR codes for each vehicle and calculate success percentage
const getCollection = async (vehicle_id, date) => {
  try {
      // Fetch assigned QR codes for the vehicle
      const assignedQRResponse = await getFromAPI(`getassignedQR/?vehicle_id=${vehicle_id}&date_assigned=${date}&CheckStatus=true`);

      // Ensure that there are destinations with QR codes assigned
      if (assignedQRResponse.destinations && assignedQRResponse.destinations.length > 0) {
        const totalQRCount = assignedQRResponse.destinations.length;

        // Extract QR Code IDs from destinations
        const qrCodeIds = assignedQRResponse.destinations.map(destination => destination.qr_code);

        // Fetch scan logs for the QR codes
        const scanLogsResponse = await getFromAPI(`qrscanlog/?qrCodeId=${qrCodeIds.join(',')}&CheckStatus=true`);

        if (scanLogsResponse.qrScanLogData && scanLogsResponse.qrScanLogData.length > 0) {
          // Filter scan logs for the current date
          const filteredLogs = scanLogsResponse.qrScanLogData.filter(log => log.created_date.startsWith(date));

          // Use a Set to filter out duplicate QR code scans
          const uniqueScans = new Set(filteredLogs.map(log => log.qrCodeId));
          const successfulScans = uniqueScans.size; // Get the number of unique scans for today

          // Calculate success percentage
          const successPercentage = (successfulScans / totalQRCount) * 100;
          return { successPercentage: successPercentage.toFixed(2), totalQRCount, scanQR: successfulScans };
        } 
        else
        {
          return { successPercentage: '0', totalQRCount, scanQR: 0 };
        }
      }
    return { successPercentage: '0', totalQRCount: 0, scanQR: 0 }; // Return 0 values if no data is found
  } catch (error) {
    console.error('Error fetching data:', error);
    return { successPercentage: '0', totalQRCount: 0, scanQR: 0 }; // Return 0 in case of an error
  }
};

  useEffect(() => {
    //fetchDashBoardData();
    //fetchqrscanlogdata();
    fetchwarddata();
  }, []);

  useEffect(() => {
    if (dashboardData.length > 0) {
      fetchAllCollections();
    }
  }, [dashboardData]);

  DataTable.Buttons.jszip(JSZip); 
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const fetchAllCollections = async () => {

    // Get values from input elements or use stored date values
    const fromDateInput = $("#fromDate").val() || storedFromDate || new Date().toISOString().split('T')[0]; // Default to today if not selected
    const toDateInput = $("#toDate").val() || storedToDate || new Date().toISOString().split('T')[0]; // Default to today if not selected

    const fromDate = new Date(fromDateInput);
    const toDate = new Date(toDateInput);

    // Store the selected dates for future use
    var storedFromDate = fromDateInput;
    var storedToDate = toDateInput;

    const tableElement = $("#maindatatable");
    const datePickerContainer = $(".date-filter");

    // Check if DataTable is already initialized and destroy it
    if ($.fn.dataTable.isDataTable(tableElement)) {
      tableElement.DataTable().clear().destroy(); // Clear and destroy the existing DataTable
    }

    // Add From and To Date Pickers along with Search Button
    datePickerContainer.html(`
      <div class="d-flex align-items-end gap-3 justify-content-end mb-2">
        <div class="from-date d-flex align-items-center gap-2">
          <label for="fromDate">From:</label>
          <input type="date" id="fromDate" class="form-control form-control-sm" />
        </div>
        <div class="to-date d-flex align-items-center gap-2">
          <label for="toDate">To:</label>
          <input type="date" id="toDate" class="form-control form-control-sm" />
        </div>
        <div class="search-btn">
          <button id="searchBtn" class="btn btn-primary btn-sm">Search</button>
        </div>
      </div>
    `);

    // Set the input fields back to the selected dates (to retain the selection)
    $("#fromDate").val(storedFromDate);
    $("#toDate").val(storedToDate);

    // Show the DataTable with a loading row initially
    tableElement.html(`
      <thead>
        <tr>
          <th>No.</th>
          <th>Vehicle Number</th>
          <th>Driver Name</th>
          <th>Assigned Date</th>
          <th>Total Assigned</th>
          <th>Total Scan</th>
          <th>Collection Points %</th>
          <th>Completed Collections</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="10" style="text-align: center; padding: 20px;">Loading...</td>
        </tr>
      </tbody>
    `);

    const today = new Date().toISOString().split('T')[0];
    let pendingCount = 0;
    let completeCount = 0;
    let inProcessCount = 0;

    const dataWithCollection = await Promise.all(
      dashboardData.map(async (data, i) => {
        const { successPercentage, totalQRCount, scanQR } = await getCollection(data.vehicle.id, data.date_assigned);
        let statusText = 'Pending';
        let barColor = '';
        let barPercentage = '0%';
        let statusBar = 'danger';

        if (parseFloat(successPercentage) === 0) {
          statusText = 'Pending';
          barColor = 'danger';
          statusBar = 'danger';
          pendingCount++;
        } else if (parseFloat(successPercentage) > 0 && parseFloat(successPercentage) < 100) {
          statusText = 'In Progress';
          barColor = 'warning';
          statusBar = 'warning';
          inProcessCount++;
          barPercentage = `${successPercentage}%`;
        } else if (parseFloat(successPercentage) === 100) {
          statusText = 'Completed';
          barColor = 'primary';
          barPercentage = '100%';
          statusBar = 'success';
          completeCount++;
        }

        setComplete(completeCount);
        setInProcess(inProcessCount);
        setPending(pendingCount);

        return [
          i + 1,
          data.vehicle?.registrationNumber ?? "NA", // Default to "NA" if registrationNumber is missing
          data.driver?.FirstName ?? "NA",           // Default to "NA" if FirstName is missing
          data.date_assigned ?? "NA",               // Default to "NA" if date_assigned is missing
          totalQRCount ?? 0,                        // Default to 0 if totalQRCount is missing
          scanQR ?? 0,                              // Default to 0 if scanQR is missing
          successPercentage ?? 0,                   // Default to 0 if successPercentage is missing
          statusText ?? "NA",                       // Default to "NA" if statusText is missing
          statusBar ?? "NA",                        // Default to "NA" if statusBar is missing
          barColor ?? "gray",                       // Default to "gray" if barColor is missing
          barPercentage ?? 0,                       // Default to 0 if barPercentage is missing
          data.vehicleId ?? 0,                    // Default to "NA" if vehicleId is missing
        ];
      })
    );

    setTableData(dataWithCollection);

    // Initialize DataTable with filtered data
    tableElement.DataTable({
      dom: '<"date-filter" <"from-date"> <"to-date">>B<"top"lf>rtip',
      buttons: [
        {
          extend: 'excel',
          className: 'btn btn-export btn-md btn-lightdark',
          text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
        },
        {
          extend: 'pdf',
          className: 'btn btn-export btn-md btn-lightdark',
          text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to PDF'
        },
        {
          extend: 'print',
          className: 'btn btn-export btn-md btn-lightdark',
          text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
        }
      ],
      data: dataWithCollection,
      columns: [
        { title: "No." },
        { title: "Vehicle Number" },
        { title: "Driver Name" },
        { title: "Assigned Date" },
        { title: "Total Assigned" },
        { title: "Total Scan" },
        { title: "Collection Points %" },
        {
          title: "Completed Collections",
          render: function (data, type, row) {
            const barColor = row[9];
            const barPercentage = row[10];
            return `
              <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="height: 10px">
                <div class="progress-bar bg-${barColor}" style="width: ${barPercentage}"></div>
              </div>
            `;
          }
        },
        {
          title: "Status",
          render: function (data, type, row) {
            const statusText = row[7];
            const statusBar = row[8];
            return `<span class="badge badge-soft-${statusBar}">${statusText}</span>`;
          }
        },
        {
          title: "Action",
          render: function (data, type, row) {
            return `
              <button class="btn btn-light btn-sm view-details" data-compl-id="${row[11]}" data-Assigned-date="${row[3]}">Details</button>
            `;
          }
        }
      ],
      order: [[3, 'desc']],
      autoWidth: false,
      columnDefs: [
        {
          targets: -1,
          orderable: false
        }
      ],
      language: {
        search: "",
        searchPlaceholder: "Search...",
        paginate: {
          previous: "«",
          next: "»"
        }
      }
    });

    // Event delegation to handle view-complaint button click
    document.querySelector('#maindatatable').addEventListener('click', function (event) {
      if (event.target && event.target.classList.contains('view-details')) {
        const compl_id = event.target.getAttribute('data-compl-id');
        const compl_Date = event.target.getAttribute('data-Assigned-date');
        // TODO: Handle modal show based on complaint ID
        setVahicleAssignmentid(compl_id);
        vehicleDetailsModalShow(compl_id, compl_Date);
      }
    });
    // Handle Search Button Click
    $("#searchBtn").click(() => {
      const fromDate = new Date($("#fromDate").val());
      const toDate = new Date($("#toDate").val());

      if (!fromDate || !toDate) {
        alert("Please select both From and To dates.");
        return;
      }

      fetchDashBoardData();
    });
  };


  const handleCardClick = async (status) => {
    setSelectedFilter(status);

    const tableElement = $("#maindatatable");
    const datePickerContainer = $(".date-filter");

    // Check if DataTable is already initialized and destroy it
    if ($.fn.dataTable.isDataTable(tableElement)) {
      tableElement.DataTable().clear().destroy(); // Clear and destroy the existing DataTable
    }

    // Preserve the date pickers container (do not overwrite them)
    const datePickersHTML = datePickerContainer.html(); // Save the current date picker HTML

    // Show the DataTable with a loading row initially
    tableElement.html(`
      <thead>
        <tr>
          <th>No.</th>
          <th>Vehicle Number</th>
          <th>Driver Name</th>
          <th>Assigned Date</th>
          <th>Total Assigned</th>
          <th>Total Scan</th>
          <th>Collection Points %</th>
          <th>Completed Collections</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="10" style="text-align: center; padding: 20px;">Loading...</td>
        </tr>
      </tbody>
    `);
    
    let filterData = tableData.filter(item => {
      if (status === 'All') return true; // No filter applied
      // Filter based on selectedFilter criteria
      return item[7] === status; // Example: filter by statusText
    })

    // Initialize DataTable with filtered data
    tableElement.DataTable({
      dom: '<"date-filter" <"from-date"> <"to-date">>B<"top"lf>rtip',
      buttons: [
        {
          extend: 'excel',
          className: 'btn btn-export btn-md btn-lightdark',
          text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
        },
        {
          extend: 'pdf',
          className: 'btn btn-export btn-md btn-lightdark',
          text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to PDF'
        },
        {
          extend: 'print',
          className: 'btn btn-export btn-md btn-lightdark',
          text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
        }
      ],
      data: filterData,
      columns: [
        { title: "No." },
        { title: "Vehicle Number" },
        { title: "Driver Name" },
        { title: "Assigned Date" },
        { title: "Total Assigned" },
        { title: "Total Scan" },
        { title: "Collection Points %" },
        {
          title: "Completed Collections",
          render: function (data, type, row) {
            const barColor = row[9];
            const barPercentage = row[10];
            return `
              <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="height: 10px">
                <div class="progress-bar bg-${barColor}" style="width: ${barPercentage}"></div>
              </div>
            `;
          }
        },
        {
          title: "Status",
          render: function (data, type, row) {
            const statusText = row[7];
            const statusBar = row[8];
            return `<span class="badge badge-soft-${statusBar}">${statusText}</span>`;
          }
        },
        {
          title: "Action",
          render: function (data, type, row) {
            return `
              <button class="btn btn-light btn-sm view-details" data-compl-id="${row[11]}" data-Assigned-date="${row[3]}">Details</button>
            `;
          }
        }
      ],
      order: [[3, 'desc']],
      autoWidth: false,
      columnDefs: [
        {
          targets: -1,
          orderable: false
        }
      ],
      language: {
        search: "",
        searchPlaceholder: "Search...",
        paginate: {
          previous: "«",
          next: "»"
        }
      }
    });

    // Event delegation to handle view-complaint button click
    document.querySelector('#maindatatable').addEventListener('click', function (event) {
      if (event.target && event.target.classList.contains('view-details')) {
        const compl_id = event.target.getAttribute('data-compl-id');
        const compl_Date = event.target.getAttribute('data-Assigned-date');
        // TODO: Handle modal show based on complaint ID
        setVahicleAssignmentid(compl_id);
        vehicleDetailsModalShow(compl_id, compl_Date);
      }
    });
  }
  const [ComplaintsListData] = useState(ComplaintsListTableData);
  
    // Update ComplaintsStatusData when state updates
    useEffect(() => {
      // Define ComplaintsStatusData inside useEffect
      const ComplaintsStatusData = [
        {
          id: 1,
          complaintsColor: "widgets-dark",
          complaintsIcon: "bi-list-ul",
          complaintsTitle: dashboardData.length,
          complaintsTitleText: "All"
        },
        {
          id: 2,
          complaintsColor: "widgets-primary",
          complaintsIcon: "bi-ui-checks",
          complaintsTitle: inProcess,
          complaintsTitleText: "In Progress"
        },
        {
          id: 3,
          complaintsColor: "widgets-danger",
          complaintsIcon: "bi-clock-history",
          complaintsTitle: pending,
          complaintsTitleText: "Pending"
        },
        {
          id: 4,
          complaintsColor: "widgets-success",
          complaintsIcon: "bi-check2-circle",
          complaintsTitle: complete,
          complaintsTitleText: "Completed"
        }
      ];
      setComplaintsStatusData(ComplaintsStatusData);
      // You can use ComplaintsStatusData here or in the component as needed
    }, [dashboardData, inProcess, complete, pending]);

  // Set interval for auto-refreshing dashboard data
  useEffect(() => {
    // Fetch the dashboard data once on component mount
    fetchDashBoardData();

    // Set auto-reload interval using the default interval time
    const intervalId = setAutoReload(fetchDashBoardData, DEFAULT_INTERVAL_TIME); // Reload every default 60 seconds
    setAutoReloadId(intervalId);

    // Cleanup: clear interval when the component is unmounted
    return () => {
      clearAutoReload(intervalId);
    };
  }, []);


  const [fullscreen, setFullscreen] = useState(true);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Dashboard" />

          <Container>
          <Row xs={1} sm={2} lg={4} className="justify-content-center">
              {complaintsStatusData.map((data, i) =>
                <Col className="mb-4" key={i}>
                  <Card
                    className={`mb-0 h-100 widgets-card ${data.complaintsColor} ${selectedFilter === data.complaintsTitleText ? 'widgets-active' : ''}`}
                    onClick={() => handleCardClick(data.complaintsTitleText)} // Example filter based on complaintsTitle
                  >
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl">
                          <i className={`bi ${data.complaintsIcon}`}></i>
                        </div>
                        <div className="ms-3">
                          <h2 className="widgets-title fw-bold d-flex flex-wrap column-gap-2 align-items-center mb-0">{data.complaintsTitle}<span className="fs-20 fw-medium">{data.complaintsTitleText}</span></h2>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Vehicle Collection Status</Card.Title>
                    <div class="date-filter"></div>                        
                    <Table responsive bordered className="mb-0 table-nowrap" id="maindatatable">
                    <thead className="table-light">
                        <tr>
                            <th>No.</th>
                            <th>Vehicle Number</th>
                            <th>Driver Name</th>
                            <th>Assigned Date</th>
                            <th>Total Assigned</th>
                            <th>Total Scan</th>
                            <th>Collection Points %</th>
                            <th>Completed Collections</th>
                            <th>Status</th>
                            <th>Action</th>
 
                        </tr>
                    </thead>
                </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>

      {/* Complaint Details */}
      <Modal centered fullscreen={fullscreen} show={vehicleModal} onHide={vehicleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Map</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-hidden">
        <iframe
          title="Google Map of India"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d304505.0421346358!2d77.39102695013177!3d28.704059655764245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd2d58b44b2b%3A0x5d5b5c6e09e43b8!2sIndia!5e0!3m2!1sen!2sin!4v1725865186799!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Modal.Body>
      </Modal>

      {/* Vehicle Details Modal */}
      <Modal centered size="xl" backdrop="static" keyboard={false} show={vehicleDetailsModal} onHide={vehicleDetailsModalClose}>
        <Modal.Header closeButton={!loading}>
          <Modal.Title>Vehicle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <VehicleDetails vehicleDetailsData={vehicleDetails} 
              onLoadingComplete={() => setLoading(false)} // Set loading to false when data is loaded 
              />
        </Modal.Body>
      </Modal>
    </>
  );
}
